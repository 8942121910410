/* eslint-disable import/named */

// import { assets } from 'features/home/pages/home.page';

import { formatArticleTime } from '@/lib/formatDate';
import { Post } from '@/types/post';
import Link from 'next/link';
import Image from 'next/image';
import classNames from 'classnames';
import { useRouter } from 'next/router';

const DEFAULT_PHOTO = '/static/images/BG_n.png';

interface Props {
  posts: Post[];
}

// const TCell = styled(TableCell)<TableCellProps & { index: number }>`
//   padding: 8px 16px;
//   background-color: ${({ index }) => (index % 2 === 0 ? '#F8F9FC' : 'white')};
//   border-right: 1px solid rgb(233, 237, 246);
//   border-bottom: 1px solid rgb(233, 237, 246);
//   white-space: nowrap;
// `;

const TCell = ({ children, className = '' }) => {
  return (
    <td
      className={classNames('whitespace-nowrap px-4 py-2 text-sm', className)}
    >
      {children}
    </td>
  );
};

const TableView = ({ posts }: Props) => {
  const { locale } = useRouter();

  return (
    <table className="w-full border table-auto border-slate-100 dark:border-slate-600">
      <thead>
        <tr>
          <th>Date</th>
          <th>Title</th>
          <th>Asset</th>
          <th>Author</th>
          <th>Tags</th>
        </tr>
      </thead>
      <tbody>
        {posts.map(
          (
            {
              createdAt,
              publishedAt,
              slug,
              author,
              title,
              crypto_assets = [],
              tags = [],
            },
            index
          ) => (
            <tr
              key={index}
              className="border border-slate-100 even:bg-slate-100 dark:even:bg-slate-800 dark:border-slate-600"
            >
              <TCell>
                <time dateTime={publishedAt}>
                  {formatArticleTime(publishedAt as string, locale)}
                </time>
              </TCell>
              <TCell>
                <Link href={`/${slug}`} legacyBehavior>
                  <a className="text-blue-600 whitespace-nowrap hover:underline">
                    {title}
                  </a>
                </Link>
              </TCell>
              <TCell>
                <div className="flex flex-wrap items-center gap-1 min-w-[160px]">
                  {crypto_assets.map((asset) => (
                    <img
                      key={`asset-${asset.slug}`}
                      src={asset.avatar}
                      alt={asset.name}
                      width={20}
                      height={20}
                      className="overflow-hidden border-none rounded-lg outline-none"
                    />
                  ))}
                </div>
              </TCell>
              <TCell>
                <div className="flex flex-row items-center mt-1 space-x-1 font-sans align-middle sm:mt-3">
                  <div className="mr-1.5 flex-shrink-0">
                    <a href="#">
                      <img
                        className="w-5 h-5 rounded-full"
                        src={author?.avatar || DEFAULT_PHOTO}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="opacity-90">
                    <p className="flex flex-col">
                      <a
                        href="#"
                        className="text-[12px] font-medium capitalize hover:underline"
                      >
                        {author?.username}
                      </a>
                    </p>
                  </div>
                </div>
              </TCell>

              <TCell>
                <div className="flex flex-wrap items-center justify-end gap-x-1 gap-y-1">
                  {tags.map((tag) => (
                    <span
                      key={tag.slug}
                      className="inline-block whitespace-nowrap rounded bg-[#E9EDF6] px-1 py-1 text-xs text-[#676F83] last:mr-0 dark:bg-[#292F38] dark:text-[#9299AA]"
                    >
                      {tag.label}
                    </span>
                  ))}
                </div>
              </TCell>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

export default TableView;
