/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';
import { FaLock } from 'react-icons/fa';
import Image from 'next/image';

import { FC } from 'react';
import type { Post } from '@/types/post';
import { formatArticleTime, getDiffFromNow } from '@/lib/formatDate';
import { BlogCardTitle, BlogTag } from './core/Text';
import { useRouter } from 'next/router';
import ContentTag from './ContentTag';
const DEFAULT_PHOTO = '/static/images/BG_n.png';
interface Props {
	post: Post;
}

interface CustomLinkProps {
	children;
	disable?: boolean;
	href?: string;
}

const CustomLink: FC<CustomLinkProps> = ({ href, disable, children }) => {
	if (disable) return <div>{children}</div>;
	return (
		<Link href={href as string} scroll={true}>
			{children}
		</Link>
	);
};

/**
 *
 * @param {Props} props
 * @returns
 */
const Article: FC<Props> = ({ post }: Props) => {
	const { slug, publishedAt, title, content, description, author, photo, tags, tickets } = post;
	const displayPhoto = photo?.[0]?.url || DEFAULT_PHOTO;
	const href = `/${slug}`;
	const { locale } = useRouter();

	return (
		<div key={title} className="mb-4 flex flex-row overflow-hidden sm:flex-col">
			<div className="relative hidden flex-shrink-0 cursor-pointer sm:block">
				<CustomLink href={href}>
					<Image
						src={displayPhoto}
						alt={title}
						width={400}
						height={300}
						className="overflow-hidden rounded-lg border-none outline-none"
						placeholder="blur"
						blurDataURL={`/_next/image?url=${displayPhoto}&w=16&q=1`}
						quality={100}
						loading="lazy"
					/>

					{content === 'disallow' && <ContentTag tickets={tickets} />}
				</CustomLink>
			</div>

			<div className="flex flex-1 flex-col justify-between sm:flex-col">
				<div className="flex-1">
					<div className="mt-2 mb-2 hidden flex-wrap gap-x-1 gap-y-1 text-xs font-medium sm:flex">
						{tags?.map((tag) => (
							<CustomLink href={`/${tag.slug}`} key={tag.slug}>
								<BlogTag>{tag.label}</BlogTag>
							</CustomLink>
						))}
					</div>
					<CustomLink href={href}>
						<div className="block sm:mt-1 sm:min-h-[42px]">
							<BlogCardTitle className="opacity-90">{title}</BlogCardTitle>
						</div>
					</CustomLink>
				</div>
				<div className="mt-2 flex flex-row items-center space-x-1 align-middle font-sans sm:mt-3">
					<div className="mr-1.5 flex-shrink-0">
						<a href="#">
							<img className="h-6 w-6 rounded-full sm:h-9 sm:w-9" src={author?.avatar || DEFAULT_PHOTO} alt="" />
						</a>
					</div>
					<div className="opacity-90">
						<p className="flex hidden flex-col sm:block">
							<a href="#" className="text-[12px] font-medium capitalize hover:underline">
								{author?.username}
							</a>
						</p>
						{/* <span>·</span> */}
						<div className="mt-[0px] text-[12px] opacity-50">
							<time dateTime={publishedAt}>{formatArticleTime(publishedAt as string, locale)}</time>
						</div>
					</div>
				</div>
			</div>

			{/* Only Mobile */}
			<div className="relative mt-1 ml-4 block flex-shrink-0 cursor-pointer sm:hidden">
				<CustomLink href={href}>
					<Image
						src={displayPhoto}
						alt={title}
						width={140}
						height={82}
						className="overflow-hidden rounded-sm border-none outline-none dark:rounded-none"
						placeholder="blur"
						blurDataURL={`/_next/image?url=${displayPhoto}&w=16&q=1`}
						quality={100}
						loading="lazy"
					/>

					{content === 'disallow' && <ContentTag tickets={tickets} />}
				</CustomLink>
			</div>
		</div>
	);
};

export default Article;
